.transform {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
a.display-content {
    display: contents;
    color: #12263F;
}

.font-100 {
    font-size: 100%;
}

.css-yk16xz-control {
     background-color:#fff !important;
     border-color: #D2DDEC !important; 
   
}

.css-1wa3eu0-placeholder {
    color: #D2DDEC !important;
}

.flatpickr-months .flatpickr-month {
    background: #fff;  
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    background:#fff;
}

span.flatpickr-weekday {
    background:#fff;
}

.flatpickr-weekdays {
    background: #fff;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
    background-color: #fff;
}

.flatpickr-days {
     border-left: none;
    border-right: none;
}

.flatpickr-calendar.hasTime .flatpickr-time {
     border: none;
     border-top:1px solid  #D2DDEC !important;
}

.modal-footer.border-0 {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
}
.bg-gradient {
    background-image: linear-gradient(to bottom right, rgb(71, 180, 117, 0.9), rgb(51, 152, 213, 0.8));
}

@media only screen and (max-width:767px) {
    .mob-m-0 {
        margin: 0px !important;
    }
    .mob-mb-20{
        margin-bottom: 20px;
    }
    .pagination-lg .page-link, .pagination-lg .page {
        padding: 0.7rem 1rem !important;
    }

    .mob-pl-5{
        padding-left: 5rem;
    }
    .mobile-none {
        display: none;
    }

 .mobile-block {
    display: block !important;
}

.mobile-block .input-group-flush {
    padding: 0px 21px;
}

}

form.mobile-view.mobile-block {
    display: none;
}
.cursor-pointer {
    cursor: pointer;
}

.opacity-8 {
    opacity: .7;
}

.font-sm {
    font-size: .7rem;
}

/*-------------------shilpi cliffex---------------------*/

.error{
    border: 1px solid red;
}

.err-msg{
    color: red;
    font-size: 12px;
}
/* ul.pagination li {
    padding: 10px;
    cursor: pointer;
  } */
  .pagination{
    justify-content: center;
  }
  .pagination a{
    color: black;
  }
  /* .pagination .active{
    border-bottom:1px solid  rgb(71, 180, 117, 0.9);
  } */
  .pagination .disabled a{
    color: gray;
  }
  

  .input-group-append {
    position: absolute;
    right: 4px;
    top: 5px;
    z-index: 9;
}


/*-----------------------rajat cliffex-----------------------*/
	
.actionStyle{
    text-align: right;
}	

tr.providerListStyle {
    background: rgba(0,0,0,.4) !important;
} 

.adddoctor .card-body {
    overflow-y: inherit;
}

.adddoctor .modal-dialog {
    height: 100%;
}
.editStyle{
    margin-right: 20px;
    margin-left: 20px;
}

.popUpButton{
    background: #47b475;
    color: #fff;
    border: none;
    border-radius: 25px;
    width: 9rem;
    height: 2.5rem;
}

.popupbuttonfield{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: center; 
    align-items: center;
}

.listHeading th {
    background: #47b475 !important;
    color:#fff !important;
}

.eyeGap{
    margin-right: 13px;
}

.copyContent{
    display: flex;
    flex-direction:row;
}

.copyButton{
    margin-left: 5rem;
    margin-top: 4px;
}

.height-100 {
    max-height: 600px;
    height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
}
.large-modal{
    max-width: 800px;
}

.billingTd{
    font-size: 10px;
}

.navbar-active-tab{
    top: 0.5rem;
    bottom: 0.5rem;
    left: 0;
    right: auto;
    border-left: 2px solid #FFFFFF;
    border-bottom: 0;
}

.contactUsTag{
    margin-left: 5px;
}

.adddoctor::before {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #12263F;
    content: "";
}

.text-color {
    color: #95AAC9;
}



.white-space{
    white-space: nowrap;
}

.pagination li{
    font-size: .9375rem; 
    padding: .65rem .1rem;
    background-color: #fff;
    border: 1px solid #e3ebf6;
}

.pagination li a{
    height:.93rem;
    width:.93rem;
    padding: .5rem 1.2rem;
}

.pagination .previous{
    padding: .75rem .25rem;
}


.pagination .previous a{
    padding: .65rem .1rem;
}


.pagination .next{
    padding: .75rem .5rem;
}


.pagination .next a{
    padding: .65rem .1rem;
}

.pagination li.active{
    background-color: #47b475;
    color: #fff;
}

.pagination li:first-child{
    border-top-left-radius: .5rem;
    line-height: 1.5;
    border-bottom-left-radius: .5rem;
    
}

.pagination li:last-child{
    border-top-right-radius: .5rem; line-height: 1.5;
    border-bottom-right-radius: .5rem;
    
}

/* font-size: .9375rem; */

/* .dashboard_block{
    width: 18rem;
    height: 10rem;
    border-radius: .60rem;
    background-color: #fff;
     margin: 1.5rem; 
} */

/* .dashboard_block:hover{
    box-shadow: 0px 0px 10px rgba(0,0,0, .6);
} */

/* 
.main_dashboard{
    background-color: #e9ecef;
} */

.block_menu{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.graph_block{
    width: 100%;
    height: 540px;
    border-radius: .60rem;
    background-color: #FFFFFF;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

/* .graph_block:hover{
    box-shadow: 0px 0px 15px rgba(0,0,0, .6);
} */

.recharts-wrapper{
    background-color: #FFFFFF;
    width: 100% !important;
}

.dashboard_block_image .recharts-wrapper{
    background-color: #fff !important;
}

.bg-blue{
    background: #007bff
}
 .card-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 22px;
    margin: 25px;
    box-shadow: 5px 3px 10px 0 rgb(21 15 15 / 30%);
    border-radius: 10px;
    text-align: center;
    color: #fff;
    
}

.bg-orange{
    background-color: #fd7e14;
}

.bg-green{
    background-color: #28a745;
}
.bg-purple{
    background-color: #6777ef;
}

.bg-red{
    background-color: #721c24;
}

.bg-yellow{
    background-color: #f5bb29;
}

/* .recharts-layer .recharts-active-dot{
    display: none;
} */

.recharts-dot{
    display: none;
}

.newDocSelect{

   margin: 1rem;
   margin-left: 3rem;
    width: 100%;
}

.newDocSelectYear{
    margin: 1rem;
    /* margin-left: 0; */
    width: 10rem;
}

.choose_btn_one{
    color:#82ca9d;
    border:1px solid #82ca9d;
    background-color: #fff;
    margin-left: .5rem;
    /* width: 100%; */
    border-radius: .5rem;
    height: 1.8rem;
    padding: 3px 10px;
    cursor: pointer;
}

.choose_btn_two{
    color:#8884d8;
    border:1px solid #8884d8;
    background-color: #fff;
    margin-left: .5rem;
    /* width: 100%; */
    border-radius: .5rem;
    height: 1.8rem;
    padding: 3px 10px;
    cursor: pointer;
}

.choose_btn_three{
    color:#fd7e14;
    border:1px solid #fd7e14;
    background-color: #fff;
    margin-left: .5rem;
    /* width: 100%; */
    border-radius: .5rem;
    height: 1.8rem;
    padding: 3px 10px;
    cursor: pointer;
}

.active_button_one{
    border: 1px solid #82ca9d;
    color: #fff;
    border-radius: .5rem;
    background-color: #82ca9d;
    /* width: 100%; */
    margin-left: .5rem;
    padding: 3px 10px;
    cursor: pointer;
}

.active_button_two{
    border: 1px solid #8884d8;
    color: #fff;
    border-radius: .5rem;
    background-color: #8884d8;
    /* width: 100%; */
    margin-left: .5rem;
    padding: 3px 10px;
    cursor: pointer;
}

.active_button_three{
    border: 1px solid #fd7e14;
    color: #fff;
    border-radius: .5rem;
    background-color: #fd7e14;
    /* width: 100%; */
    margin-left: .5rem;
    padding: 3px 10px;
    cursor: pointer;
}

.Subscription_view{
    cursor: pointer;
}


.statistic_block{
    width: 100%;
    height: 540px;
    border-radius: .60rem;
    background-color: #FFFFFF;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.pieLebelCustom{
    margin-left: 1rem;
    width: 327px;
    height: auto;
    background-color: rgb(245, 245, 245);
    border: 1px solid rgb(213, 213, 213);
    border-radius: 3px
}

.pieColorDivA{
    width: 1rem;
    height: 1rem;
    background-color: rgb(0, 136, 254);
}

.pieColorDivB{
    width: 1rem;
    height: 1rem;
    background-color: rgb(0, 196, 159);
}

.pieColorDivC{
    width: 1rem;
    height: 1rem;
    background-color: rgb(255, 187, 40);
}
/* sssssssss */
.dummy-pie-chart {
    position: relative;
}

.dummy-pie-chart .dummy-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 250px;
    border: 14px solid #3d908f96;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
}

.cu-recharts-1 .recharts-default-legend li {
    display: block!important;
    text-align: left!important;
    margin: 0!important;
    white-space: nowrap;
}

.cu-recharts-1 .recharts-default-legend {
    padding: 5px!important;
}


.graph_select_color .css-1wa3eu0-placeholder {
    color: #000 !important;
}

.recharts-legend-item-text {
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .recharts-layer .recharts-cartesian-axis-tick{
    font-weight: bold;
  }

  .recharts-legend-wrapper{
    padding-top: 1rem;
  }

/* .recharts-pie{
    width: 150px !important;
    height: 150px !important;
} */

.graph_select_color.css-2b097c-container {
    min-width: 150px;
    padding-right: 15px;
}

.graph_select_color.css-2b097c-container.newSelectStyle {
    min-width: 150px;
    padding-right: 0;
}

.card-header-title.d-flex-header{ 
    display: flex; align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.card-header-title.d-flex-header h4{
    margin-bottom: 0px;
}

@media only screen and (max-width:1024px) {
    .card-header-title.d-flex-header{ 
        display: inline-block !important; 
    }

    .card-header-title.d-flex-header h4{
        padding-bottom: 10px;
    }

    .mobile-view.mobile-none.form-padding {
        padding-bottom: 10px;
    }

    .height-auto{
        height: auto !important;
    }
}
@media(max-width:768px){
    .sm_over_block{
        overflow-x: auto;
        min-height: 200px;
    }
}
.spinner-border.statistic-spinner{
   width: 7rem;
   height: 7rem;  
}

.dummy-pie-chart {
    display: inherit;
}

.loader-wrapper{
    display: flex;
    padding-top: 7rem;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.table-responsive-sm {
    overflow-x: auto;
}

.statistic-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 250px;
    background-color: #f5f5f5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
}

.input-group .form-control:focus {
    box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #47b475 !important;
    border-color: #47b475 !important;
 }

 .practise_or_prov_checkbox{
     display: flex;
     flex-direction: row;
     padding-top: 1rem;
 }

 .search_dropdown_box{
    height: auto;
    width: 95%;
    background: #fff;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding: 0px 0px 0px 0px;
    overflow: auto;
    border: 1px solid lightgray;
    z-index: 100;
    position: absolute;
    /* top:100% */
 }

 .search_prac_network_group {
    height: auto;
    width: 100%;
  }
  
  .search_prac_network_group_data {
    height: auto;
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
    text-align: left;
    border-bottom: 1px solid #e0e0e5;
    cursor: pointer;
  }
  
  .search_prac_network_group_data p {
    font-size: 13px;
    margin-bottom: 0px;
    color: #414146;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: 0.5px;
  }
  
  .search_prac_network_group_data:hover {
    background: #f0f0f5;
    transition: 0.2s ease-in-out;
  }

  #searchNetPrac_group_header {
    height: auto;
    width: 100%;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: #40d37d;
    text-align: left;
    font-size: 13px;
    letter-spacing: 1px;
  }

  .overContainer {
    max-height: 220px;
    overflow: auto;
}

.overContainerNew{
    max-height: 500px;
    overflow: auto;
}

.dropdown-item-new{
    color:#000;
}

.actionNetwork{
    display: flex;
    flex-direction: row;
}
